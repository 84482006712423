import React from "react"
import styled from "styled-components"

const SDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 2rem 0 0 0;
`

export default ({ tags }) => (
  <SDiv>
    {tags.map((tag, i) => (
      <p key={i}>{tag}</p>
    ))}
  </SDiv>
)
