import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./../components/layout/layout"
import Header from "./../components/layout/header"
import Footer from "./../components/layout/footer"
import Intro from "../components/intro"
import Tabs from "../components/tabs"
import styled from "styled-components"
import { withPrefix } from "gatsby"

const SImg = styled.img`
  display: flex;
  margin: 0 auto;
`

const StyledLink = props => (
  <Link to={props.to} style={{ textDecoration: `none` }}>
    {props.children}
  </Link>
)

const blog = ({ data }) => (
  <Layout>
    <Header title="My Blog" />
    <Intro
      primary="My"
      secondary="blog"
      tag="A place to track my thoughts "
      align="center"
    >
      {/* <Tabs tags={["All", "Learning", "Personal Dev", "Life", "General"]} /> */}
      {/* <SImg src="https://source.unsplash.com/random/1080x200" alt="" /> */}
      <div className="post-grid">
        {data.allMarkdownRemark.edges.map(post => (
          <div
            style={{ textAlign: "justify" }}
            className="post-container"
            key={post.node.id}
          >
            <div className="post-item">
              <div className="post-image">
                <StyledLink to={post.node.fields.slug}>
                  {" "}
                  <img
                    className="thumb-nail"
                    // src="https://source.unsplash.com/random/200x200"
                    src={withPrefix(`/${post.node.frontmatter.blog_img}`)}
                    alt=""
                  />
                </StyledLink>
              </div>

              <div className="post-content">
                <StyledLink to={post.node.fields.slug} className="header">
                  <div className="post-title">
                    <h1>{post.node.frontmatter.title}</h1>
                  </div>
                  <div className="post-excerpt">
                    <p>{post.node.frontmatter.excerpt}</p>
                  </div>
                </StyledLink>
                <div className="post-tag">
                  <p>
                    <em>{post.node.frontmatter.tag}</em>
                  </p>
                </div>
                <div className="extra">
                  <div className="post-author">
                    <p>{post.node.frontmatter.author}</p>
                  </div>
                  <div className="post-meta">
                    <p>
                      {post.node.frontmatter.date} &bull; {post.node.timeToRead}{" "}
                      min Read
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Intro>
    <Footer />
  </Layout>
)

export default blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          id
          frontmatter {
            title
            date(formatString: "MMM  DD")
            author
            tag
            blog_img
            excerpt
          }
        }
      }
    }
  }
`
